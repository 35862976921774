import { useSearchApi } from '~/providers/search';
import {
  ProductSearchSaveQuery,
  UserSearchSaveQuery,
  UserSearchItem,
  ProductSearchItem,
  ProductsSearchResponse,
  UsersSearchResponse,
} from '~/types/components/search';

type TParentCategory = 'man' | 'woman' | 'kids' | 'lifestyle';

export const useHeaderSearch = () => {
  const cancelHistory = 'cancelHistory';
  const cancelSearchProducts = 'cancelSearchProducts';
  const cancelSearchUsers = 'cancelSearchUsers';

  const activeTab = useState<TParentCategory>('headerSearchActiveTab', () => 'woman');
  const headerSearchQuery = useState('headerSearchQuery', () => '');
  const historyData = useState<[ProductsSearchResponse, UsersSearchResponse] | null>('headerSearchHistoryData', () => null);
  const historyPending = useState('headerSearchHistoryPending', () => false);
  const searchId = useState<string | null>('searchId', () => null);
  const historySearchId = useState<string | null>('historySearchId', () => null);
  const usageSearchId = useState<string | null>('usageSearchId', () => null);
  const isGoToNextPageFromSearch = useState<boolean>('isGoToNextPageFromSearch', () => false);

  const { isHeaderSearchFull, isHeaderSearchMobileDialog } = useHeader();

  const {
    searchApi,
    getHistoryUsers,
    getHistoryProducts,
    searchProduct,
    searchUser,
    saveSearchProduct,
    saveSearchUser,
  } = useSearchApi();

  const {
    data: productsData,
    pending: productsPending,
    execute: getProducts,
  } = useAsyncData(() => searchProduct({
    base: activeTab.value, query: headerSearchQuery.value,
  }, cancelSearchProducts), { immediate: false });

  const {
    data: usersData,
    pending: usersPending,
    execute: getUsers,
  } = useAsyncData(() => searchUser({ query: headerSearchQuery.value }, cancelSearchUsers), {
    immediate: false,
  });

  productsPending.value = false;
  usersPending.value = false;

  async function getHistory() {
    historyPending.value = true;

    const { data } = await useAsyncData(() => Promise.all([
      getHistoryProducts(cancelHistory),
      getHistoryUsers(cancelHistory),
    ]));

    historySearchId.value = data.value?.map((d) => d.id).join('-') ?? null;

    historyData.value = data.value;
    historyPending.value = false;
  }

  const globalSearchId = computed(() => searchId.value ?? historySearchId.value);

  const historyProducts = computed(() => historyData.value?.[0].values);
  const historyUsers = computed(() => historyData.value?.[1].values);
  const isShowHistory = computed(() => !!historyProducts.value?.length || !!historyUsers.value?.length);

  const resultProducts = computed(() => productsData.value?.values);
  const resultUsers = computed(() => usersData.value?.values);
  const isResultLoading = computed(() => productsPending.value || usersPending.value);

  const isResults = computed(() => !!resultProducts.value?.length || !!resultUsers.value?.length);

  async function getResults(onlyProducts?: boolean) {
    const result = await Promise.all([
      getProducts(),
      onlyProducts ? null : getUsers(),
    ]);

    return result;
  }

  async function showResults(onlyProducts?: boolean) {
    if (historyPending.value && headerSearchQuery.value.length >= 3) {
      searchApi.abortRequest(cancelHistory);
    }

    if (isResultLoading.value) {
      searchApi.abortRequest(cancelSearchProducts);
      searchApi.abortRequest(cancelSearchUsers);
    }

    if (!historyPending.value && headerSearchQuery.value.length < 3 && !historyData.value) {
      getHistory();
    }

    if (headerSearchQuery.value.length >= 3) {
      await getResults(onlyProducts);

      searchId.value = [ productsData.value?.id, usersData.value?.id ].join('-');
    } else {
      searchId.value = null;
    }
  }

  function handleError(err:unknown) {
    throw err;
  }

  function goToCatalog(product: ProductSearchItem) {
    const brand = product.brand_id ? `brand=${product.brand_id}` : '';
    const category = product.category_id ? `categoriesIds=${product.category_id}` : '';
    const filter = product.attribute_value_ids ? `filter=${product.attribute_value_ids.join('%2C')}` : '';

    const model = product.product_model_id ? `model=${product.product_model_id}` : '';

    if (brand || category || filter || model) {
      const params = [
        brand,
        category,
        filter,
        model,
      ].filter((str) => str !== '').join('&');

      // TODO: сделать переход по именованному роуту
      navigateTo(`/catalog/${product.url ? product.url : product.base}?${params}`);
    }

    isHeaderSearchFull.value = false;
    headerSearchQuery.value = '';
  }

  async function handleProductClick(product: ProductSearchItem, index: number) {
    const params: ProductSearchSaveQuery = {
      base: activeTab.value,
      query: headerSearchQuery.value,
      result_id: productsData.value?.id ?? '',
      result_select: index,
    };

    try {
      saveSearchProduct(params);
    } catch (err) {
      handleError(err);
    }

    goToCatalog(product);
    historyData.value = null;
  }

  function goToUser(user: UserSearchItem) {
    navigateTo(`/profile/${user.id}`);

    isHeaderSearchFull.value = false;
    headerSearchQuery.value = '';
  }

  async function handleUserClick(user: UserSearchItem, index: number) {
    const params: UserSearchSaveQuery = {
      query: headerSearchQuery.value,
      result_id: usersData.value?.id ?? '',
      result_select: index,
    };

    try {
      saveSearchUser(params);
    } catch (err) {
      handleError(err);
    }

    goToUser(user);
    historyData.value = null;
  }

  const isShowResults = computed(() => headerSearchQuery.value.length >= 3 || (!isShowHistory.value && (productsData.value || usersData.value)));

  return {
    headerSearchQuery,
    activeTab,
    historyPending,
    productsData,
    usersData,
    historyProducts,
    historyUsers,
    isShowHistory,
    resultProducts,
    resultUsers,
    isResultLoading,
    isResults,
    showResults,
    goToCatalog,
    handleProductClick,
    goToUser,
    handleUserClick,
    isHeaderSearchFull,
    isHeaderSearchMobileDialog,
    isShowResults,
    searchId,
    historySearchId,
    globalSearchId,
    usageSearchId,
    isGoToNextPageFromSearch,
  };
};
