export function useConcierge() {
  const { activeMenuCategory } = useActiveMenuCategory();

  const isConciergeDialogOpen = useState('isConciergeDialogOpen', () => false);
  const isConciergePage = useState<boolean | undefined>('isConciergePage', () => false);

  const sexMap = {
    women: 'female',
    men: 'male',
    kids: '',
    lifestyle: '',
  };

  function goToConcierge() {
    isConciergeDialogOpen.value = false;

    let sex;

    if (activeMenuCategory.value) {
      sex = sexMap[activeMenuCategory.value];
    }

    if (sex) {
      return navigateTo(`/conciergeLink/${sex}`);
    }

    return navigateTo('/conciergeLink');
  }

  return {
    isConciergeDialogOpen,
    isConciergePage,
    goToConcierge,
  };
}
