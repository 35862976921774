import { HttpClient, RequestParams } from '~/restAPI/http-client';
import {
  ProductSearchQuery,
  ProductSearchSaveQuery,
  ProductsSearchResponse,
  UserSearchQuery,
  UserSearchSaveQuery,
  UsersSearchResponse,
} from '~/types/components/search';

export class Search<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  private static getSearchUrl(path: string) {
    const osk = useCookie('osk');
    const address = `/${path}/${osk.value ? osk.value : ''}`;

    return address;
  }

  /**
   * No description
   *
   * @tags search-api
   * @name getHistoryProductsUsingGet
   * @summary getHistoryProducts
   * @request GET: /history/products/{osk}
   */
  public getHistoryProductsUsingGet = (
    query: {
      size: number,
    },
    params: RequestParams = {},
  ) =>
    this.request<ProductsSearchResponse>({
      path: Search.getSearchUrl('history/products'),
      method: 'GET',
      query: {
        ...query,
        _: new Date().getTime(),
      },
      ...params,
    });

  /**
   * No description
   *
   * @tags search-api
   * @name getHistoryProductsUsingGet
   * @summary getHistoryProducts
   * @request GET:/history/users/{osk}
   */
  public getHistoryUsersUsingGet = (
    query: {
      size: number,
    },
    params: RequestParams = {},
  ) =>
    this.request<UsersSearchResponse>({
      path: Search.getSearchUrl('history/users'),
      method: 'GET',
      query: {
        ...query,
        _: new Date().getTime(),
      },
      ...params,
    });

  /**
   * No description
   *
   * @tags search-api
   * @name searchProductUsingGet
   * @summary searchProduct
   * @request GET: /search_product/{osk}
   */
  public searchProductUsingGet = (
    query: ProductSearchQuery,
    params: RequestParams = {},
  ) =>
    this.request<ProductsSearchResponse>({
      path: Search.getSearchUrl('search_product'),
      method: 'GET',
      query: {
        ...query,
        _: new Date().getTime(),
      },
      ...params,
    });

  /**
   * No description
   *
   * @tags search-api
   * @name saveProductSearchHistoryUsingGet
   * @summary saveProductSearchHistory
   * @request GET: /search_product/{osk}
   */
  public saveProductSearchHistoryUsingGet = (
    query: ProductSearchSaveQuery,
    params: RequestParams = {},
  ) =>
    this.request<ProductsSearchResponse>({
      path: Search.getSearchUrl('search_product'),
      method: 'GET',
      query: {
        ...query,
        _: new Date().getTime(),
      },
      ...params,
    });

  /**
   * No description
   *
   * @tags search-api
   * @name searchUserUsingGet
   * @summary searchUser
   * @request GET: /suggest_user/{osk}
   */
  public searchUserUsingGet = (
    query: UserSearchQuery,
    params: RequestParams = {},
  ) =>
    this.request<UsersSearchResponse>({
      path: Search.getSearchUrl('suggest_user'),
      method: 'GET',
      query: {
        ...query,
        _: new Date().getTime(),
      },
      ...params,
    });

  /**
   * No description
   *
   * @tags search-api
   * @name saveUserSearchHistoryUsingGet
   * @summary saveUserSearchHistory
   * @request /suggest_user/{osk}
   */
  public saveUserSearchHistoryUsingGet = (
    query: UserSearchSaveQuery,
    params: RequestParams = {},
  ) =>
    this.request<UsersSearchResponse>({
      path: Search.getSearchUrl('suggest_user'),
      method: 'GET',
      query: {
        ...query,
        _: new Date().getTime(),
      },
      ...params,
    });
}
