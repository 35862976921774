import { useApi } from '~/restAPI';
import { Search } from '~/restAPI/Search';
import {
  ProductSearchQuery,
  ProductSearchSaveQuery,
  UserSearchQuery,
  UserSearchSaveQuery,
  ProductCategory,
} from '~/types/components/search';

import {
  categoryRootWomenId, categoryRootMenId,
  categoryRootKidsId, categoryRootLifestyleId,
} from '~/components/catalog/consts';

const searchSizeParams: { size: number } = { size: 10 };

export const productCategories:ProductCategory[] = [
  {
    id: 'woman', name: 'composables.useGender.female', url: 'zhenskoe', categoryId: categoryRootWomenId,
  },
  {
    id: 'man', name: 'composables.useGender.male', url: 'muzhskoe', categoryId: categoryRootMenId,
  },
  {
    id: 'kids', name: 'composables.useGender.child', url: 'detskoe', categoryId: categoryRootKidsId,
  },
  {
    id: 'lifestyle', name: 'composables.useGender.lifestyle', url: 'lifestyle', categoryId: categoryRootLifestyleId,
  },
];

export function useSearchApi() {
  const config = useRuntimeConfig();
  const { createApiInstance } = useApi(process.dev ? undefined : config.public.SEARCH_SRV_API_PATH);
  const searchApi = createApiInstance(Search);

  async function getHistoryProducts(cancelToken?: string) {
    return searchApi
      .getHistoryProductsUsingGet(searchSizeParams, {
        format: 'json',
        cancelToken,
      })
      .then((data) => data.data);
  }

  async function getHistoryUsers(cancelToken?: string) {
    return searchApi
      .getHistoryUsersUsingGet(searchSizeParams, {
        format: 'json',
        cancelToken,
      })
      .then((data) => data.data);
  }

  async function searchProduct(query: ProductSearchQuery, cancelToken: number | string) {
    return searchApi
      .searchProductUsingGet({ ...query, ...searchSizeParams }, { format: 'json', cancelToken })
      .then((data) => data.data);
  }

  async function saveSearchProduct(query: ProductSearchSaveQuery) {
    return searchApi
      .saveProductSearchHistoryUsingGet({ ...query, ...searchSizeParams }, { format: 'json' })
      .then((data) => data.data);
  }

  async function searchUser(query: UserSearchQuery, cancelToken: number | string) {
    return searchApi
      .searchUserUsingGet({ ...query, ...searchSizeParams }, { format: 'json', cancelToken })
      .then((data) => data!.data!);
  }

  async function saveSearchUser(query: UserSearchSaveQuery) {
    return searchApi
      .saveUserSearchHistoryUsingGet({ ...query, ...searchSizeParams }, { format: 'json' })
      .then((data) => data!.data!);
  }

  return {
    searchApi,
    getHistoryProducts,
    getHistoryUsers,
    searchProduct,
    searchUser,
    saveSearchProduct,
    saveSearchUser,
  };
}
