export function useMorePagesData() {
  const morePagesCount = useState('morePagesCount', () => 0);
  const moreCurrentPage = useState('moreCurrentPage', () => 0);
  const isStartFromZero = useState('isStartFromZero', () => true);

  const isMorePages = computed(() => (isStartFromZero.value ? moreCurrentPage.value + 1 : moreCurrentPage.value) < morePagesCount.value);

  return {
    morePagesCount,
    moreCurrentPage,
    isMorePages,
    isStartFromZero,
  };
}
